import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    useBreakpointValue,
    useToast,
} from "@chakra-ui/react";

import { useLoginMutation } from "redux/services/apiService";
import { isUserAuthenticated } from "redux/slices/user";
import { useAppSelector } from "redux/store";

import SideBarLogin from "components/NavBar/SideBarLogin";

const Login: React.FC = () => {
    const { t } = useTranslation();
    const isAuthenticated = useAppSelector(isUserAuthenticated);

    const navigate = useNavigate();
    const [loginMutation] = useLoginMutation();
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        setError,
    } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const toast = useToast();
    const [show, setShow] = useState(false);

    const handleClick = () => setShow(!show);

    const onSubmit = async (values) => {
        try {
            await loginMutation({
                email: values.email,
                password: values.password,
            }).unwrap();

            toast({
                title: t("login_successfully"),
                isClosable: true,
                status: "success",
                duration: 3000,
            });
            navigate("/");
        } catch (error) {
            setError("email", { type: "manual", message: " " });
            setError("password", { type: "manual", message: " " });
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate]);
    const isMobile = useBreakpointValue({ base: true, md: false });

    if (!isAuthenticated) {
        return (
            <>
            <Flex 
                justifyContent={isMobile ? "start" : "center"} 
                h={isMobile ? "100vh" : "100vh"}
                direction={isMobile ? "column" : "row"}
            >
                <SideBarLogin />
                <Stack bg="#F7F7F9" 
                    h={isMobile ? "100vh" : "100%"} 
                    flexDir="column" 
                    justifyContent={isMobile ? "start" : "center"} 
                    alignItems={isMobile ? "start" : "center" }
                    w={isMobile ? "100%" : "70%"}>
                    <Box 
                        minW={{ base: "90%", md: "50%" }} 
                        display="flex" 
                        flexDir="column" 
                        alignItems={isMobile ? "start" : "center"}
                        pt={isMobile ? "10%" : "5%"}
                        >
                        <Text 
                            fontSize={isMobile ? "4xl" : "5xl" } 
                            px={isMobile ? "5%" : "10%"} 
                            textAlign={isMobile ? "left" : "center"} 
                            m="0" 
                            fontFamily={'Inter'} 
                            fontWeight="bold"
                            color={"#1E3364"}
                            >
                            Selamat Datang
                        </Text>
                        <Text 
                            w={isMobile ? "100%" : "75%"} 
                            px={isMobile ? "5%" : "10%"} 
                            textAlign={isMobile ? "start" : "center"} 
                            fontFamily={'Inter'}
                            fontSize={isMobile ? "sm" : "md"}    
                        >
                            Silahkan masukkan alamat email dan password untuk masuk pada akun anda
                        </Text>
                    </Box>
                    <Box minW={{ base: "100%", md: "468px" }}>
                        <Stack spacing={4} p="1rem" backgroundColor="whiteAlpha.900" boxShadow={isMobile ? "" : "md"}>
                            <FormControl isInvalid={!!errors.email}>
                                <FormLabel htmlFor="email">{t("email")}</FormLabel>
                                <Input
                                    placeholder="e.g. your.email@example.com"
                                    borderColor={errors.email ? "red.500" : "inherit"}
                                    {...register("email", { required: t("email_required") })}
                                />
                                <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl mt={4} isInvalid={!!errors.password}>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <InputGroup size="md">
                                    <Input
                                        pr="4.5rem"
                                        type={show ? "text" : "password"}
                                        placeholder="Enter your password"
                                        borderColor={errors.password ? "red.500" : "inherit"}
                                        {...register("password", { required: t("password_required") })}
                                    />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                                            {show ? <RxEyeClosed /> : <RxEyeOpen />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
                            </FormControl>
                            <Flex justifyContent="end" alignItems="center">
                                <Text
                                    as={Link}
                                    to="/forget-password"
                                    fontSize="12px"
                                    color="#1b3056"
                                    _hover={{ color: "#16273d", textDecoration: "underline" }}
                                >
                                    Lupa Password?
                                </Text>
                            </Flex>
                            <Button
                                textColor="white"
                                bg="#1b3056"
                                isLoading={isSubmitting}
                                onClick={handleSubmit(onSubmit)}
                                _hover={{ bg: "#3c69b7" }}
                            >
                                Login
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Flex>
            
            </>
        );
    }

    return null;
};

export default Login;
