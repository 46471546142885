// eslint-disable-next-line
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Box, Flex, IconButton, Text, useBreakpointValue } from "@chakra-ui/react";

import { subMenuName } from "redux/slices/menu";
import { useAppSelector } from "redux/store";
import { ChevronLeftIcon } from "@chakra-ui/icons";
const TopMenu = lazy(() => import("./MenuBar/topMenu"));
const Header = lazy(() => import("./Header/Header"));

// const NavBar = lazy(() => import("components/NavBar/Navbar"));
//

const Layout: React.FC = () => {
    const subName = useAppSelector(subMenuName);
    const { pathname } = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    
    // Function to toggle collapse
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const [, setScrollPosition] = useState(0);
    const [marginTop, setMarginTop] = useState(84); // default mt=84

    // Function to handle scroll and update margin-top based on scroll position
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setScrollPosition(currentScrollPos);

        // If scroll is at the top (0), margin-top should be 84, otherwise 0
        if (currentScrollPos > 0) {
            setMarginTop(0);
        } else {
            setMarginTop(84);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const isMobile = useBreakpointValue({ base: true, md: false });
    return (
        <Flex direction="column" minH="100vh" bgColor="white">
            {pathname !== "/login" &&
                <Suspense fallback={<div>Loading...</div>}>
                    <Header />
                </Suspense>
            }

            <Suspense fallback={<div>Loading...</div>}>
                    {pathname !== "/login" && (
                        <Flex
                            as="nav"
                            direction="column"
                            position="fixed"
                            top="0"
                            display={isMobile ? "none" : "flex"}
                            left="0"
                            height="100vh"
                            width={isCollapsed ? "80px" : "300px"} // Lebar sidebar collapse
                            bg="white"
                            borderRight="1px solid #e2e8f0"
                            boxShadow="md"
                            mt={marginTop}
                            zIndex="1000"
                            transition="width 0.3s, margin-top 0.3s" // Add mt transition as well
                        >
                            {/* Button untuk collapse/expand sidebar */}
                            <IconButton
                                icon={<ChevronLeftIcon />}
                                aria-label="Toggle Sidebar"
                                onClick={toggleSidebar}
                                position="absolute"
                                top="5px" // Sesuaikan dengan tinggi yang Anda inginkan
                                right="-15px" // Tempatkan di luar sidebar
                                bg="white"
                                borderRadius="full"
                                border="1px solid #e2e8f0"
                                boxShadow="md"
                                zIndex="1500" // Agar tombol tetap di atas elemen lainnya
                            />

                            <TopMenu isCollapsed={isCollapsed}/>
                        </Flex>
                    
                    )}
                   {
                    pathname === "/login" ? (
                        <Box 
                            w="100%" 
                            ml="0" 
                            transition="all 0.3s ease" 
                            p="0">
                        {/* Customize for login page */}
                        <Text
                            position="relative"
                            fontWeight="bold"
                            fontSize="17px"
                            fontFamily="Inter, sans-serif"
                            color="#1A202C"
                            w="100%"
                            bgColor="white"
                            margin="0"
                        >
                        </Text>
                        <Outlet />
                        </Box>
                    ) : (
                        <>
                            {isMobile ? (
                                <>
                                    <Box
                                        w="100%"
                                        p={5}
                                        transition="all 0.3s ease"
                                    >
                                    <Text
                                        position="relative"
                                        fontWeight="bold"
                                        fontSize="17px"
                                        fontFamily="Inter, sans-serif"
                                        color="#1A202C"
                                        w="100%"
                                        bgColor="white"
                                        margin="0"
                                    >
                                        {subName}
                                    </Text>
                                    <Outlet />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box
                                        w={{ base: "calc(100% - 100px)", md: !isCollapsed ? "calc(100% - 320px)" : "calc(100% - 100px)" }}
                                        ml={{ base: "100px", md: !isCollapsed ? "320px" : "100px" }}
                                        mt={{ base: "20px", md: !isCollapsed ? "0" : "20px" }}
                                        transition="all 0.3s ease"
                                    >
                                    <Text
                                        position="relative"
                                        fontWeight="bold"
                                        fontSize="17px"
                                        fontFamily="Inter, sans-serif"
                                        color="#1A202C"
                                        w="100%"
                                        bgColor="white"
                                        margin="0"
                                    >
                                        {subName}
                                    </Text>
                                    <Outlet />
                                    </Box>
                                </>
                            )}
                        
                        </>
                    )
                    }
            </Suspense>
            {/* <div style={{ height: "100vh" }} /> */}
        </Flex>
    );
};

export default Layout;
