import { AxiosError, AxiosRequestConfig } from "axios";

import axios from "utils/axios";
import { BASE_URL } from "utils/constants";

type BaseQuery = {
    url: string;
    method?: AxiosRequestConfig<any>["method"];
    data?: AxiosRequestConfig<any>["data"];
    params?: AxiosRequestConfig<any>["params"];
};
const baseQuery = async ({ url, method = "get", data, params }: BaseQuery) => {
    try {
        const result = await axios({
            url: `${BASE_URL}/api/v1${url}`,
            method,
            data,
            params,
            headers: {
                "Content-Type": "application/json",  // Ensure correct headers
                Accept: "application/json",
            },
        });

        if (result.data.error) {
            throw new Error(result.data.error);
        }

        return { data: result.data };
    } catch (axiosError) {
        let err = axiosError as AxiosError;

        let data = {
            message: err.message,
        };
        if (err.response?.data) {
            data = {
                message: err.response?.data?.message || "",
            };
        }
        return {
            error: {
                status: err.response?.status || 500,
                data,
            },
        };
    }
};

export default baseQuery;
